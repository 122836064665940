@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.App {
    display: flex;
    flex-direction: column;

    max-width: 600px;
    min-height: 100vh;
    margin: auto;
    padding: layout.$padding--large;


    .steps {
        display: flex;
        gap: layout.$padding--small;
        flex: 0 0;

        width: 100%;
        margin: 0 auto layout.$padding;

        > div {
            flex: 1 1;

            height: 9px;

            background: rgba(color.$black, 0);
            border: 1px solid rgba(color.$black, .25);
            border-radius: layout.$border-radius--large;
            transition: background animation.$speed, border-color animation.$speed;

            &.active {
                background: rgba(color.$black, .25);
                border: 1px solid rgba(color.$black, 0);
            }
        }
    }

    .List {
        display: flex;
        text-align: center;
        flex-direction: column;
        width: 100%;

        margin: layout.$padding auto;
        padding: 0;

        li {
            width: 100%;
        }

        &.columns {
            display: grid;
            gap: layout.$padding--small;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;

            li {
                margin: 0;
            }
        }
    }

    em {
        font-weight: 400;
    }

    .Question {
        display: flex;
        flex-direction: column;
        flex: 1 1 min-content;
        min-height: 0;

        text-align: center;

        img {
            margin: layout.$padding auto;
            width: 80%;

            &.logo-text {
                width: 40%;
            }
        }

        strong, h2 {
            font-weight: 600;
            font-size: font.$size--large;
        }

        h2 {
            text-align: center;
        }

        em {
            font-size: font.$size--large;
            line-height: 1.5em;
            font-weight: 400;
        }

        p {
            font-size: font.$size--large;
            font-weight: 500;
            line-height: 1.5em;
        }

        > * {
            flex: 0 1;
        }

        .map {
            display: flex;
            flex-direction: column;
            flex: 1 1;

            .mapboxgl-map {
                flex: 1 1 100%;
            }
        }

        .map-wrapper {
            padding: layout.$padding;
            flex: 1 1 min-content;
            min-height: 15vh;
        }

        .Buttons {
            display: flex;

            margin-top: layout.$padding--large;

            .Button {
                display: flex;
                align-items: center;

                margin-right: 0;
                margin-left: auto;

                &.secondary {
                    margin-left: 0;
                }
            }

            span {
                display: inline-block;

                + svg {
                    margin-left: layout.$padding--small;
                }
            }

            svg {
                font-size: 1.2em;
            }
        }
    }

    input {
        width: 100%;

        margin: auto;
        padding: layout.$padding--small;

        border: 2px solid rgba(color.$black, .5);
        border-radius: layout.$border-radius;
        outline: none;
        transition: border-color animation.$speed;

        &:focus, &:active {
            border-color: rgba(color.$blue, 1);
        }

        + input {
            margin-top: layout.$padding;
        }
    }

    .InputQuestion {
        input {
            margin: layout.$padding 0;
        }
    }

    .PinQuestion {
        ul {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: layout.$padding--small;

            min-height: 5em;

            padding: 0;
            margin: layout.$padding auto;

            list-style: none;

            li {
                top: 0;
                height: 100%;

                background: color.$blue--lightest;
                border-radius: layout.$border-radius;
                border: 2px solid rgba(color.$blue, 0);
                box-shadow: layout.$box-shadow;
                transition: border-color animation.$speed, top animation.$speed;

                &.active {
                    border-color: rgba(color.$blue, 1);
                }

                &:hover {
                    top: -4px;
                }

                button {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    width: 100%;
                    height: 100%;

                    background: none;
                    border: none;
                    cursor: pointer;

                    svg {
                        width: 40px;
                        height: auto;
                    }

                    span {
                        font-size: 8px;
                    }

                    > * {
                        margin: layout.$padding--smallest auto;
                    }
                }
            }
        }
    }


}

@import './Questions/Improvements/Improvements';
@import './Questions/Facilities/Facilities';
@import './Map/MoreParksMap';
@import './Map/FacilitiesMap';
@import './Other/Other';
@import './Questions/MoreParks/MoreParks';
@import './ThankYou/ThankYou';
