@use '~@strategies/tokens/layout';


.Improvements {

    .CheckListItem.disabled {
        opacity: .5;
    }

}
