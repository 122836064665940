@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';

$red: #FF6666;

.Modal {
    align-items: start !important;

    &.delete-confirmation {
        header, footer {
            display: none;
        }

        .Button {
            width: 100%;

            background: $red;
            text-align: center;

            &:hover {
                background: darken($red, 10);
            }

            span {
                text-align: center;
                margin: auto;
            }
        }
    }

    &--window {
        flex: 0 0 auto !important;
        min-width: 0 !important;
        width: 90vw;
        max-width: 600px !important;
        top: 0 !important;
        transform: none !important;
        margin: 5vw auto !important;
    }

    main {
        padding: layout.$padding !important;
    }

    header {
        span {
            font-size: font.$size--large;
        }

        p {
            display: none;
        }
    }

    textarea {
        width: 100%;
        padding: layout.$padding;

        font-family: font.$family;

        border: 1px solid color.$blue--light;
        border-radius: layout.$border-radius;
        outline: none;
        resize: vertical;
    }

    footer {
        display: flex;
    }

    .Button {
        display: inline-flex;
        align-items: center;
        margin: auto;
        margin-right: 0;

        svg {
            margin-left: layout.$padding--small;
        }

        &.secondary {
            margin-left: 0;
            margin-right: auto;
        }
    }
}

.ReactSelect {
    width: max-content;
    margin: 0 auto;
}
