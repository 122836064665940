@use '~@strategies/tokens/layout';


.Facilities {

    input {
        margin-top: layout.$padding;
    }

    ul {
        width: min-content;
    }

}
