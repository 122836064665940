@use '~@strategies/tokens/animation';
@use '~@strategies/tokens/color';
@use '~@strategies/tokens/font';
@use '~@strategies/tokens/layout';


.FacilitiesMap {
    border-radius: layout.$border-radius;
    height: 35vh;

      
    .mapboxgl-canvas {
        position: relative;
        top: 0;
        bottom: 0;
    }

    .map-container {

        height: 100%;
        overflow: hidden;
        border-radius: layout.$border-radius;
    }

    .mapboxgl-popup-content {

        text-align: left;
    }

    .mapbox-logo {
        display: none;
    }

    .mapboxgl-ctrl-logo {
        display: none !important;
    }

    .mapbox-improve-map {
        display: none;
    }

    .mapboxgl-ctrl-compass {
        display: none;
    }
}