@use '~@strategies/tokens/layout';


.Question.ThankYou {
    p {
        font-weight: 400;

        &:first-of-type {
            font-weight: 500;
        }
    }

    a {
        width: min-content;
        margin: layout.$padding 0 0 auto;

        text-decoration: none;
    }
}
