@use '~@strategies/tokens/layout';

.Other {
    overflow: hidden;
    width: 100%;

    padding: 0 !important;

    .CheckListItem {
        flex: 1 0 100%;
        width: 100%;
        height: 100%;
        margin: 0;
        border: none;
    }

    li {
        margin-top: 0;
    }

    > * {
        display: inline-block;
        width: 100%;
    }

    span {
        width: 100%;
    }

    > div {
        left: -90%;
        top: 0;
        display: flex;
        transition: left .25s;
    }

    &.checked {
        > div {
            left: 0%;
        }
    }

    input {
        flex: 1 0 90%;
        width: 90%;
        height: 100%;
        padding: 0 layout.$padding;
        display: block;
        border: none;
        transition: width .15s;
        line-height: 280%;
    }
}

